/**
 * @name Section
 * @description A generic section of data that can be used to display a collection of items.
 */

import Lines from "@/components/utility/lines";

import { BannerT, DesignSystemComponent } from "@/types/design-system-types";
import ComponentResolver from "@/utils/system/component-resolver";
import { cn } from "@/utils/helpers";
import Page from "@/utils/system/page";
import AssetWrapper from "../atoms/asset-wrapper";

const Banner = ({
  data, // All incoming Contentful data
  id, // For scroll spy anchors
  debug, // Debugger and live preview framer

  // Contentful controlled Props
  view = data?.fields.view ?? "horizontal",
  border = data?.fields.border ?? false,

  // Overall
  bannerBackgroundColor = data?.fields.bannerBackgroundColor,
  bannerBackgroundImage = data?.fields.bannerBackgroundImage,

  // Slot 1
  slot1 = data?.fields?.slot1 ?? false,
  slot1BackgroundColor = data?.fields.slot1BackgroundColor ?? "white-100",
  slot1BackgroundImage = data?.fields.slot1BackgroundImage,

  // Slot 2
  slot2 = data?.fields?.slot2 ?? false,
  slot2BackgroundColor = data?.fields.slot2BackgroundColor ?? "white-100",
  slot2BackgroundImage = data?.fields.slot2BackgroundImage,

  scrollSpy = data?.fields.scrollSpy,

  // Internal props
  className,
  ...props
}: DesignSystemComponent<BannerT>) => {
  // Theme classes
  const bannerThemeClass = Page.getThemeClass(bannerBackgroundColor);
  const slot1ThemeClass = Page.getThemeClass(
    bannerBackgroundColor ?? slot1BackgroundColor
  );
  const slot2ThemeClass = Page.getThemeClass(
    bannerBackgroundColor ?? slot2BackgroundColor
  );

  // Blur classes
  const bannerBlurClass = bannerBackgroundColor === "blur" && "n-banner-blur";
  const slot1BlurClass = (() => {
    switch (slot1BackgroundColor) {
      case "blur":
        return "n-banner-blur";
      case "blur-with-dark-gradient":
        return "n-banner-blur n-banner-blur-with-dark-gradient";
      default:
        return "";
    }
  })();

  const slot2BlurClass = slot2BackgroundColor === "blur" && "n-banner-blur";

  // ! Horizontal Banner View Only
  if (view === "horizontal") {
    return (
      <section
        id={id}
        className={cn(`Module n-banner n-banner-view-horizontal`, [
          bannerThemeClass,
          scrollSpy && "menu-section",
          className,
        ])}
        {...props}
      >
        {debug}

        {/* Whole Banner Background Image */}
        <div className={cn("n-banner-full-bg", [bannerBlurClass])}>
          {bannerBackgroundImage && (
            <AssetWrapper className="n-banner-cover" data={bannerBackgroundImage} />
          )}
        </div>

        {/* Slot 1 Background Color & Image */}
        <div className={cn("n-banner-slot-1-bg", [slot1ThemeClass, slot1BlurClass])}>
          {slot1BackgroundImage && (
            <AssetWrapper className="n-banner-cover" data={slot1BackgroundImage} />
          )}
        </div>

        {/* Slot 2 Background Color & Image */}
        <div className={cn("n-banner-slot-2-bg", [slot2ThemeClass, slot2BlurClass])}>
          {slot2BackgroundImage && (
            <AssetWrapper
              className="n-banner-cover"
              data={slot2BackgroundImage}
              desktopOnly={true}
            />
          )}
        </div>

        {/* Left line border */}
        {border && <Lines left />}

        {/* Content Container */}
        <div className="main-section main-section-y-md relative z-[2] xl-max:px-6">
          <div
            className={cn(
              "n-banner-slot-container",
              (slot1 || slot1BackgroundImage) && (slot2 || slot2BackgroundImage)
                ? "grid-rows-2"
                : "grid-rows-1"
            )}
          >
            {/* Slot 1 Container */}
            <div className={cn("n-banner-slot n-banner-slot-1", slot1ThemeClass)}>
              {/* Slot 1 Mobile only image // ? Allows background image placement on mobile  */}
              {slot1BackgroundImage && (
                <AssetWrapper
                  className="absolute left-0 top-0 h-1/2 w-full object-cover lg:hidden lg:h-full"
                  data={slot1BackgroundImage}
                  mobileOnly={true}
                />
              )}

              {/* Slot 1 Content */}
              {slot1 && (
                <ComponentResolver
                  data={slot1}
                  theme={bannerBackgroundColor ?? slot1BackgroundColor}
                />
              )}
            </div>

            {/* Slot 2 Container */}
            <div className={cn("n-banner-slot n-banner-slot-2", slot2ThemeClass)}>
              {/* Slot 2 Mobile only image // ? Allows background image placement on mobile  */}
              {slot2BackgroundImage && (
                <AssetWrapper
                  className={cn("h-full w-full object-cover lg:hidden", [
                    slot2 ? "absolute left-0 top-0" : "relative",
                  ])}
                  data={slot2BackgroundImage}
                />
              )}
              {/* Slot 2 Content */}
              {slot2 && (
                <ComponentResolver
                  className="relative"
                  data={slot2}
                  theme={bannerBackgroundColor ?? slot1BackgroundColor}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }

  // ! Vertical Banner View Only
  if (view === "vertical") {
    const slot1BgImageClass = slot1 && "n-banner-absolute";
    const slot1Class = !slot1 && "n-banner-absolute";

    const slot2BgImageClass = slot2 && "n-banner-absolute";
    const slot2Class = !slot2 && "n-banner-absolute";

    return (
      <section
        {...props}
        className={cn(`n-banner n-banner-view-vertical`, [bannerThemeClass, className])}
      >
        {debug}

        {/* Whole Banner Background Image */}
        {bannerBackgroundImage && (
          <div className={cn("n-banner-full-bg", [bannerBlurClass])}>
            <AssetWrapper data={bannerBackgroundImage} />
          </div>
        )}

        {/* Slot 1 Container */}
        <div className={cn("n-banner-slot-1-bg", [slot1ThemeClass, slot1BlurClass])}>
          {/* Slot 1 Background Image */}
          {slot1BackgroundImage && (
            <AssetWrapper
              className={slot1BgImageClass as string}
              data={slot1BackgroundImage}
            />
          )}

          {/* Slot 1 Content */}
          {slot1 && (
            <div className={cn("main-section main-section-y-sm", [slot1Class])}>
              <div className="n-banner-slot-container">
                <div className={cn("n-banner-slot n-banner-slot-1", slot1ThemeClass)}>
                  {slot1 && <ComponentResolver className="relative z-[1]" data={slot1} />}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Slot 2 */}
        <div className={cn("n-banner-slot-2-bg", [slot2ThemeClass, slot2BlurClass])}>
          {/* Slot 2 Background Image */}
          {slot2BackgroundImage && (
            <AssetWrapper
              className={slot2BgImageClass as string}
              data={slot2BackgroundImage}
            />
          )}

          {/* Slot 2 Content */}
          {slot2 && (
            <div className={cn("main-section main-section-y-sm", [slot2Class])}>
              <div className="n-banner-slot-container">
                <div className={cn("n-banner-slot n-banner-slot-2", slot2ThemeClass)}>
                  {slot2 && <ComponentResolver className="relative z-[1]" data={slot2} />}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Left line border */}
        {border && <Lines left />}
      </section>
    );
  }

  return (
    <>
      <p className="text-red-600">Invalid banner view.</p>
    </>
  );
};

export default Banner;
